import React, { Component } from "react";
import { Form } from "reactstrap";
import { Button } from "primereact/button";
import api from "../../services/api";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import formValid from "../Components/FormValidate";
import { locale } from "../../locales/index";
import to from "../Components/to.js";
import { Messages } from "primereact/messages";
import { valorAD } from "../../index";
import { Toast } from 'primereact/toast';

import "./AutenticacaoAD/styles/App.css";
import { SignInButton } from "./AutenticacaoAD/components/SignInButton";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      login: "",
      pass: "",
      form: {
        login: { valor: "", isRequired: true },
        pass: { valor: "", isRequired: true },
      },
      hidden: true,
      formErrors: {
        login: "",
        pass: "",
      },
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let formErrors = { ...this.state.formErrors };
    let form = { ...this.state.form };

    switch (name) {
      case "login":
        formErrors.login = value.length < 1 ? locale.msgCampoObrigatorio : "";
        form.login.valor = value;
        break;
      case "pass":
        formErrors.pass = value.length < 1 ? locale.msgCampoObrigatorio : "";
        form.pass.valor = value;
        break;
      default:
        break;
    }

    this.setState({ formErrors, form });
  };

  formSubmitHandler = (e) => {
    e.preventDefault();
    if (formValid(this.state)) {
      let funcionarios = {
        username: this.state.form.login.valor,
        password: this.state.form.pass.valor,
      };
      this.authentication(funcionarios);
    } else {
      this.toast.show({
        severity: "error",
        summary: "Erro!",
        detail: locale.erro_logra,
      });
    }
  };


  handlerAD = (e) => {
    e.preventDefault();
    let funcionarios = {
      username: localStorage.getItem("userPrincipalName"),
      password: localStorage.getItem("id") + ";" + localStorage.getItem("id")
    };
    this.authentication(funcionarios);
  };

  authentication = async (funcionarios) => {
    this.setState({ loading: true });
    const [, response] = await to(api.post(`auth`, funcionarios));
    if (localStorage.getItem("config") === null) {
      const config = await api.get("config")
      localStorage.setItem("config", JSON.stringify(config.data[0]))
    }
    if (!response) {
      this.toast.show({
        severity: "error",
        summary: "Erro!",
        detail: locale.denied_auth,
      });
      this.setState({ loading: false });
      return;
    }
    if (response.data.token) {
      var token = response.data.token;
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var json = JSON.parse(jsonPayload);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("nivelUser", json.nivel);
      localStorage.setItem("timeExpireToken", json.exp);
      localStorage.setItem("idLogado", json.id);
      localStorage.setItem("alterarSenha", true);
      localStorage.setItem("username", json.sub);
      let user = {
        user: json.sub,
      };

      const checkBD = await to(api.post(`/checkBD/`, user));

      if (checkBD[1].data === "Sucess") {
        const respFirst = await to(api.post(`/firstLogin/`, user));
        localStorage.setItem("nameUser", respFirst[1].data.user.login);

        if (respFirst[1].data.user.primeiroAcesso === 1) {
          localStorage.setItem("alterarSenha", true);
          this.props.history.push(`/primeiroLogin?user=${respFirst[1].data.user.login}`);
        }
        else {
          localStorage.lastsession = new Date().getTime() + 1000000 * 1000000;
          this.props.history.push("/home");
        }
      } else {
        this.toast.show({
          severity: "error",
          summary: "Erro!",
          detail: locale.msgErroAPI,
        });
      }
    } else {
      this.toast.show({
        severity: "error",
        summary: "Erro!",
        detail: locale.msgErro,
      });
    }
    this.setState({ loading: false });
  };

  render() {
    const { formErrors } = this.state;

    if (valorAD === 0) {
      return (
        <div className="login-body">
          <Toast ref={(el) => (this.toast = el)} baseZIndex={999999} />
          <Form onSubmit={this.formSubmitHandler}>
            <div className="login-panel"></div>

            <div className="login-content">
              <img src="assets/layout/images/Savetyre.jpg" alt="babylon-layout" style={{ marginBottom: 115 }} />

              <h1>{locale.nomeSistema}</h1>
              <p>{locale.login_welcome}</p>

              <div className="login-input-wrapper" style={{ marginBottom: 15 }}>
                <InputText
                  name="login"
                  className={formErrors.login.length > 0 ? "error" : ""}
                  value={this.state.form.login.valor}
                  onChange={this.handleChange}
                  placeholder={locale.login_email}
                />

                {formErrors.login.length > 0 && (
                  <div className="col-12">
                    <span className="errorMessage">{formErrors.login}</span>
                  </div>
                )}
              </div>

              <div className="login-input-wrapper" style={{ marginBottom: 15 }}>
                <Password
                  feedback={false}
                  name="pass"
                  className={formErrors.pass.length > 0 ? "error" : ""}
                  value={this.state.form.pass.valor}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  placeholder={locale.login_senha}
                />
                {formErrors.pass.length > 0 && (
                  <div className="col-12">
                    <span className="errorMessage">{formErrors.pass}</span>
                  </div>
                )}
              </div>
              <br />
              <div className="col-12">
                <Button
                  loading={this.state.loading}
                  label={locale.login_logar}
                  id="login"
                  color="primary"
                  className="p-button-raised p-button-rounded px-4"
                  type="submit"
                />
              </div>
              <br></br>
              <h5>
                <span>Versão : </span> {locale.versaoSistema}
              </h5>
            </div>
          </Form>
        </div>
      );
    } else {
      return (
        <div className="login-body">
          <Form onSubmit={this.handlerAD}>
            <div className="login-panel"></div>
            <div className="login-content">
              <img src="assets/layout/images/Savetyre.jpg" alt="babylon-layout" />
              <h1>{locale.nomeSistema}</h1>
              <p>{"Seja bem vindo!"}</p>
              <SignInButton />
              <br></br>
              <h5>
                <span>Versão : </span> {locale.versaoSistema}
              </h5>
            </div>
          </Form>
        </div>
      );
    }
  }
}

export default Login;
