import axios from "axios";
import { locale } from "../locales/index";

const api = axios.create({
  baseURL: locale.urlApi,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const token = localStorage.getItem("token");
    if (
      error.response.status === 401 &&
      error.config &&
      !originalRequest._retry &&
      token !== null
    ) {
      originalRequest._retry = true;
      return axios
        .get(locale.urlApi + "auth/refresh", {
          headers: {
            Authorization: "Bearer " + token,
            isRefreshToken: true,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token);
            api.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
            return api(originalRequest);
          }
        });
    }

    return Promise.reject(error);
  }
);

export default api;
