//import React from "react";
import React, { useState } from "react";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";
import { Button } from "primereact/button";
import Login from "../Login";
import App from "../../../App";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */

export const ProfileContent = () => {

    const { instance, accounts } = useMsal();
    const [load, setLoad] = useState(false);

    React.useEffect(() => {
        function RequestProfileData() {
            // Silently acquires an access token which is then attached to a request for MS Graph data
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                callMsGraph(response.accessToken).then(response => {
                    localStorage.setItem("userPrincipalName", response.userPrincipalName);
                    localStorage.setItem("id", response.id);
                    setLoad(true);
                });
            });
        }
        RequestProfileData();
        
    }, load)

    return (
        <>
            {load ? <Button
            type="submit"
            variant="secondary"
            className="p-button-raised p-button-rounded px-4"
            label={"Acessar"}/> : ""}
        </>
    )
};

export function ADApp() {
    return (
        <>
            <AuthenticatedTemplate>
                <App/>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
        </>
    );
}
