const formValid = ({ formErrors, form }) => {
  let valid = true;
  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  /*Object.values(form).forEach(val => {
    val.isRequired === true && val.valor === "" && (valid = false);
  });*/

  return valid;
};
export default formValid