import React, { Component, useEffect, useState } from "react";
import { Redirect, HashRouter, Route, Switch } from "react-router-dom";
import { locale } from "./locales";
import { addLocale } from "primereact/api";
import PrimeReact from 'primereact/api';
import { valorAD } from "./index"
import "./Style.scss";

const AppLayout = React.lazy(() => import("./layout/AppLayout"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const LoginAD = React.lazy(() => import("./pages/Login/LoginAD"));
const AlterarSenha = React.lazy(() => import("./pages/Login/AlterarSenha"));
const loading = () => <div className="animated fadeIn pt-3 text-center"></div>;

function App() {

  PrimeReact.ripple = true;

  const isAuthenticated = () =>
    localStorage.getItem("token") !== null

  setInterval(() => {
    const elements = document.getElementsByClassName("apexcharts-menu-icon");

    [...elements].forEach((element) =>
      element.addEventListener("mouseover", () => element.setAttribute("title", "Imagem"))
    );
  }, 1000);

  addLocale('pt', {
    firstDayOfWeek: 0,
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Sm"
  });

  if (valorAD === 1) {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/loginAD" name="LoginAD" render={(props) => <LoginAD {...props} />} />

            <Route
              render={(props) =>
                isAuthenticated() ? (
                  <Route path="/" name="Home" render={(props) => <AppLayout {...props} />} />
                ) : (<Redirect to="/loginAD"></Redirect>)
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  } else {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />

            <Route
              path="/primeiroLogin"
              name="interativit"
              render={(props) =>
                isAuthenticated() ? (
                  <AlterarSenha {...{ ...props, firstLogin: true }} />
                ) : (
                  <Redirect to="/login"></Redirect>
                )
              }
            />

            <Route
              render={(props) =>
                isAuthenticated() ? (
                  <Route path="/" name="Home" render={(props) => <AppLayout {...props} />} />
                ) : (<Redirect to="/login"></Redirect>)
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}


export default App;
